// MUI ICons
// const Api = dynamic(() => import('@mui/icons-material/Api'), { loading: () => null });
import Code from '@mui/icons-material/Code';
// const DeveloperBoard = dynamic(() => import('@mui/icons-material/DeveloperBoard'), { loading: () => null });
import HomeRounded from '@mui/icons-material/HomeRounded';
import KeyboardRounded from '@mui/icons-material/KeyboardRounded';
import MonitorRounded from '@mui/icons-material/MonitorRounded';
import ReviewsRounded from '@mui/icons-material/ReviewsRounded';
// const Storage = dynamic(() => import('@mui/icons-material/Storage'), { loading: () => null });

/** ========================================================= * NextJS Material Dashboard 2 PRO - v2.0.0 ========================================================= * Product Page: https://www.creative-tim.com/product/nextjs-material-dashboard-pro * Copyright 2022 Creative Tim (https://www.creative-tim.com) Coded by www.creative-tim.com ========================================================= * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software. */

/*
{
        name: "Back End",
        icon: <DeveloperBoard />,
        iconColor: "success",
        collapse: [
          {
            name: "Express",
            route: "/tutorials/express/home",
          },
        ],
      },
      {
        name: "Storage",
        icon: <Storage />,
        iconColor: "error",
        collapse: [
          {
            name: "MongoDB",
            route: `/tutorials/mongoDB/home`,
          },
        ],
      },
      {
        name: "Libraries",
        icon: <Api />,
        iconColor: "warning",
        collapse: [
          {
            name: "Stripe",
            route: `/tutorials/stripe/home`,
          },
        ],
      },
*/

const routes = [
  {
    name: 'Home',
    icon: <HomeRounded />,
    route: '/',
  },
  {
    name: 'Skills',
    icon: <KeyboardRounded />,
    route: '/#skills',
  },
  {
    name: 'Reviews',
    icon: <ReviewsRounded />,
    route: '/#reviews',
  },
  {
    name: 'my work',
    icon: <MonitorRounded />,
    route: '/#my-work',
  },
  {/*
    name: 'tutorials',
    icon: <Code />,
    columns: 1,
    rowsPerColumn: 1,
    collapse: [
      {
        name: 'Front End',
        icon: <MonitorRounded />,
        iconColor: 'info',
        collapse: [
          {
            name: 'React',
            route: '/tutorials/react/home',
          },
        ],
      },
    ],
  */},
];

export default routes;
