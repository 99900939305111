/* eslint-disable no-shadow */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
/* eslint-disable no-param-reassign */
/**
========================================================= * NextJS Material Dashboard 2 PRO TS - v1.0.0 ========================================================= * Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts * Copyright 2022 Creative Tim (https://www.creative-tim.com) Coded by www.creative-tim.com ========================================================= * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software. */

// react
import { Fragment, useState, useEffect } from 'react';
import { useMaterialUIController } from 'context';

// next
import dynamic from 'next/dynamic';
import Link from 'next/link';

// prop-types
import PropTypes from 'prop-types';

// MUI
import MDBox from "components/MDBox";
import Popper from '@mui/material/Popper';
import Grow from '@mui/material/Grow';

// PRO
import breakpoints from 'assets/theme/base/breakpoints';

// routes
import routes from 'routes';

// MUI
const Collapse = dynamic(() => import('@mui/material/Collapse'), { loading: () => null });
const Container = dynamic(() => import('@mui/material/Container'), { loading: () => null });
const Divider = dynamic(() => import('@mui/material/Divider'), { loading: () => null });
const Grid = dynamic(() => import('@mui/material/Grid'), { loading: () => null });

// MUI Icons
const ArrowDropUp = dynamic(() => import('@mui/icons-material/ArrowDropUp'), { loading: () => null });
const KeyboardArrowDown = dynamic(() => import('@mui/icons-material/KeyboardArrowDown'), { loading: () => null });
const KeyboardArrowRight = dynamic(() => import('@mui/icons-material/KeyboardArrowRight'), { loading: () => null });
const Close = dynamic(() => import('@mui/icons-material/Close'), { loading: () => null });
const Menu = dynamic(() => import('@mui/icons-material/Menu'), { loading: () => null });

// PRO
const MDButton = dynamic(() => import('components/MDButton'), { loading: () => null });
const MDTypography = dynamic(() => import('components/MDTypography'), { loading: () => null });

function DefaultMenuItem({
  name, icon, children, collapseStatus, light, href, route, collapse, ...rest
}) {
  const [controller] = useMaterialUIController();
  const [mobileView, setMobileView] = useState(false);

  // mobile
  useEffect(() => {
    function evalMobileView() {
      if (window.innerWidth < breakpoints.values.lg) setMobileView(true);
      else setMobileView(false);
    }
    window.addEventListener('resize', evalMobileView);
    evalMobileView();
    return () => window.removeEventListener('resize', evalMobileView);
  }, []);

  const template = (
    <MDBox
      display="flex"
      alignItems="baseline"
      mx={2}
      py={0.5}
      style={{
        display: 'flex', alignItems: 'center', cursor: 'pointer', userSelect: 'none',
      }}
    >
      {icon && (
        <MDTypography variant="body2" lineHeight={1} color={light ? 'white' : 'dark'} sx={{ alignSelf: 'center', '& *': { verticalAlign: 'middle' } }}>
          {icon}
        </MDTypography>
      )}
      <MDTypography
        variant="button"
        fontWeight="regular"
        textTransform="capitalize"
        color={light ? 'white' : 'dark'}
        sx={{ fontWeight: '100%', ml: 1, mr: 0.25 }}
      >
        {name}
      </MDTypography>
      {collapse && (
        <MDTypography variant="body2" color={!mobileView ? 'white' : mobileView && controller.darkMode ? 'white' : 'dark'} ml="auto">
          <KeyboardArrowDown sx={{ fontWeight: 'normal', verticalAlign: 'middle' }} />
        </MDTypography>
      )}
    </MDBox>
  );

  return (
    <>
      {/* link  or nolink */}
      {route ? (
        <Link
          {...rest}
          color={light ? 'white' : 'dark'}
          opacity={light ? 1 : 0.6}
          sx={{ cursor: 'pointer', userSelect: 'none' }}
          href={route}
          scroll={route === '/'}
        >
          <span style={{ display: 'flex', alignItems: 'center' }}>{template}</span>
        </Link>
      ) : (
        <MDBox {...rest} color={light ? 'white' : 'dark'} opacity={light ? 1 : 1} sx={{ cursor: 'pointer', userSelect: 'none' }}>
          {template}
        </MDBox>
      )}
      {children && (
        <Collapse in={Boolean(collapseStatus)} timeout={400} unmountOnExit>
          {children}
        </Collapse>
      )}
    </>
  );
}

function DefaultNavbar({ transparent, light }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [dropdown, setDropdown] = useState('');
  const [dropdownEl, setDropdownEl] = useState('');
  const [dropdownName, setDropdownName] = useState('');
  const [nestedDropdown, setNestedDropdown] = useState('');
  const [nestedDropdownEl, setNestedDropdownEl] = useState('');
  const [nestedDropdownName, setNestedDropdownName] = useState('');
  const [arrowRef, setArrowRef] = useState(null);
  const [mobileNavbar, setMobileNavbar] = useState(false);
  const [mobileView, setMobileView] = useState(false);
  const [collapse, setCollapse] = useState(''); // from OG

  // mobile
  useEffect(() => {
    function evalMobileView() {
      setMobileNavbar(false);
      if (window.innerWidth < breakpoints.values.lg) setMobileView(true);
      else setMobileView(false);
    }
    window.addEventListener('resize', evalMobileView);
    evalMobileView();
    return () => window.removeEventListener('resize', evalMobileView);
  }, []);

  return (
    <Container sx={{ position: 'relative' }}>
      <MDBox py={1} px={{ xs: 4, sm: transparent ? 2 : 3, lg: transparent ? 0 : 2 }} my={3} mx={3} width="calc(100% - 48px)" borderRadius="lg" shadow={transparent ? 'none' : 'md'} color={light ? 'white' : 'dark'} position="absolute" left={0} zIndex={99} sx={({ palette: { transparent: transparentColor, white, background }, functions: { rgba } }) => ({ backgroundColor: transparent ? transparentColor.main : rgba(controller.darkMode ? background.sidenav : white.main, 0.8), backdropFilter: transparent ? 'none' : 'saturate(200%) blur(30px)' })}>
        <MDBox display="flex" justifyContent="space-between" alignItems="center">
          <Link href="/">
            <MDBox py={transparent ? 1.5 : 0.75} lineHeight={1} pl={{ xs: 0, lg: 1 }}>
              <MDTypography variant="button" fontWeight="bold" color={light ? 'white' : 'dark'} sx={{ cursor: 'pointer' }}>CodeFox</MDTypography>
            </MDBox>
          </Link>
          <MDBox color="inherit" display={{ xs: 'none', lg: 'flex' }} m={0} p={0} justifyContent="flex-end" flexGrow="1" sx={{ alignItems: 'center' }}>
            {routes.map((menuItem) => (
              <DefaultMenuItem
                key={menuItem.name}
                name={menuItem.name}
                icon={menuItem.icon}
                href={menuItem.href}
                route={menuItem.route}
                collapse={Boolean(menuItem.collapse)}
                onMouseEnter={({ currentTarget }) => {
                  if (menuItem.collapse) {
                    setDropdown(currentTarget);
                    setDropdownEl(currentTarget);
                    setDropdownName(menuItem.name);
                  }
                }}
                onMouseLeave={() => menuItem.collapse && setDropdown(null)}
                light={light}
              />
            ))}
          </MDBox>

          {/* contact */}
          <MDBox display={{ xs: 'none', lg: 'inline-block' }}>
            <Link href="/#contact" scroll={false}>
              <MDButton
                variant="gradient"
                color={controller.sidenavColor ? controller.sidenavColor : 'dark'}
                size="small"
              >
                contact
              </MDButton>
            </Link>
          </MDBox>

          {/* menu toggle */}
          <MDBox display={{ xs: 'inline-block', lg: 'none' }} lineHeight={0} py={1.5} pl={1.5} color={controller.darkMode ? 'white' : 'dark'} sx={{ cursor: 'pointer' }} onClick={() => setMobileNavbar(!mobileNavbar)}>
            {mobileNavbar ? <Close fontSize="default" /> : <Menu fontSize="default" />}
          </MDBox>
        </MDBox>
        <MDBox
          bgColor={darkMode ? 'dark' : 'white'}
          shadow={transparent ? 'lg' : 'none'}
          borderRadius="md"
          px={transparent ? 2 : 0}
        >
          {mobileView && (
            <Collapse in={Boolean(mobileNavbar)} timeout="auto" unmountOnExit>
              {/* mobile menu */}
              <MDBox width="calc(100% + 1.625rem)" my={2} ml={-2}>
                {routes.map(({
                  name,
                  icon,
                  collapse: routeCollapses,
                  href,
                  route,
                  collapse: navCollapse,
                }) => (
                  <DefaultMenuItem
                    key={`mobile-${name}`}
                    name={name}
                    icon={icon}
                    collapseStatus={name === collapse}
                    onClick={
                      () => navCollapse !== false
                        && (collapse === name ? setCollapse(false) : setCollapse(name))
                    }
                    href={href}
                    route={route}
                    collapse={Boolean(navCollapse)}
                  >
                    {/* hidden / shown part */}
                    <MDBox sx={{ height: '15rem', maxHeight: '15rem', overflowY: 'scroll' }}>
                      {routeCollapses && routeCollapses.map((item) => (
                        <MDBox key={item.name} px={2}>
                          <MDBox width="100%" display="flex" alignItems="center" p={1}>
                            <MDBox display="flex" justifyContent="center" alignItems="center" width="1.5rem" height="1.5rem" borderRadius="md" color="text" mr={1} fontSize="1rem" lineHeight={1}>
                              {item.icon}
                            </MDBox>
                            <MDTypography display="block" variant="button" fontWeight="bold" textTransform="capitalize">
                              {item.name}
                            </MDTypography>
                          </MDBox>
                          {item.collapse.map((el, index) => (
                            <Link key={el.name} href={el.route}>
                              <MDTypography
                                minWidth="11.25rem"
                                display="block"
                                variant="button"
                                color="text"
                                textTransform="capitalize"
                                fontWeight="regular"
                                py={0.625}
                                px={5}
                                mb={index === item.collapse.length - 1 ? 2 : 0}
                                sx={({ palette: { grey, dark }, borders: { borderRadius } }) => ({
                                  borderRadius: borderRadius.md, cursor: 'pointer', transition: 'all 300ms linear', '&:hover': { backgroundColor: grey[200], color: dark.main },
                                })}
                              >
                                {el.name}
                              </MDTypography>
                            </Link>
                          ))}
                        </MDBox>
                      ))}
                    </MDBox>
                  </DefaultMenuItem>
                ))}
              </MDBox>

              {/* contact */}
              <MDBox mt={2} mb={2}>
                <Link href="/#contact">
                  <MDButton variant="contained" color={controller.darkMode ? 'info' : 'dark'} fullWidth>Contact</MDButton>
                </Link>
              </MDBox>
            </Collapse>
          )}
        </MDBox>
      </MDBox>

      {/* dropdown menu */}
      <Popper
        anchorEl={dropdown}
        popperRef={null}
        open={Boolean(dropdown)}
        placement="bottom-start"
        transition
        style={{ zIndex: 999 }}
        modifiers={[{ name: 'arrow', enabled: true, options: { element: arrowRef } }]}
        onMouseEnter={() => setDropdown(dropdownEl)}
        onMouseLeave={() => {
          if (!nestedDropdown) {
            setDropdown(null);
            setDropdownName('');
          }
        }}
      >
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            sx={{ transformOrigin: 'left top', background: ({ palette: { dark, white } }) => (darkMode ? dark.main : white.main) }}
          >
            <MDBox borderRadius="lg">
              <MDTypography variant="h1" color={darkMode ? 'dark' : 'white'}>
                <MDBox ref={setArrowRef} sx={{ mt: -3, color: darkMode ? '#344767' : 'white' }}>
                  <ArrowDropUp />
                </MDBox>
              </MDTypography>
              <MDBox shadow="lg" borderRadius="lg" p={1.625} mt={1}>
                {routes.map(
                  ({
                    name, collapse, columns, rowsPerColumn,
                  }) => {
                    let template;

                    // Render the dropdown menu that should be display as columns
                    if (collapse && columns && name === dropdownName) {
                      const calculateColumns = collapse.reduce((resultArray, item, index) => {
                        const chunkIndex = Math.floor(index / rowsPerColumn);
                        if (!resultArray[chunkIndex]) {
                          resultArray[chunkIndex] = [];
                        }
                        resultArray[chunkIndex].push(item);
                        return resultArray;
                      }, []);
                      template = (
                        <Grid key={name} container spacing={3} py={1} px={1.5}>
                          {calculateColumns.map((cols, key) => {
                            const gridKey = `grid-${key}`;
                            const dividerKey = `divider-${key}`;
                            return (
                              <Grid
                                key={gridKey}
                                item
                                xs={12 / columns}
                                sx={{ position: 'relative' }}
                              >
                                {cols.map((col, index) => (
                                  <Fragment key={col.name}>
                                    <MDBox
                                      key={col.name}
                                      width="100%"
                                      display="flex"
                                      alignItems="center"
                                      py={1}
                                      mt={index !== 0 ? 2 : 0}
                                    >
                                      <MDBox
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"
                                        width="1.5rem"
                                        height="1.5rem"
                                        borderRadius="md"
                                        color={col.iconColor ? col.iconColor : 'text'}
                                        mr={1}
                                        fontSize="1rem"
                                        lineHeight={1}
                                      >
                                        {col.icon}
                                      </MDBox>
                                      <MDTypography
                                        display="block"
                                        variant="button"
                                        fontWeight="bold"
                                        textTransform="capitalize"
                                      >
                                        {col.name}
                                      </MDTypography>
                                    </MDBox>
                                    {col.collapse.map((item) => (
                                      <Link key={item.name} href={item.route}>
                                        <MDTypography
                                          minWidth="11.25rem"
                                          display="block"
                                          variant="button"
                                          color="text"
                                          textTransform="capitalize"
                                          fontWeight="regular"
                                          py={0.625}
                                          px={2}
                                          sx={({
                                            palette: { grey, dark },
                                            borders: { borderRadius },
                                          }) => ({
                                            borderRadius: borderRadius.md,
                                            cursor: 'pointer',
                                            transition: 'all 300ms linear',

                                            '&:hover': {
                                              backgroundColor: grey[200],
                                              color: dark.main,
                                            },
                                          })}
                                        >
                                          {item.name}
                                        </MDTypography>
                                      </Link>
                                    ))}
                                  </Fragment>
                                ))}
                                {key !== 0 && (
                                  <Divider
                                    key={dividerKey}
                                    orientation="vertical"
                                    sx={{
                                      position: 'absolute',
                                      top: '50%',
                                      left: '-4px',
                                      transform: 'translateY(-45%)',
                                      height: '90%',
                                    }}
                                  />
                                )}
                              </Grid>
                            );
                          })}
                        </Grid>

                      );

                      // Render the dropdown menu that should be display as list items
                    } else if (collapse && name === dropdownName) {
                      template = collapse.map((item) => {
                        const itemTemplate = (
                          <MDTypography
                            key={item.name}
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            variant="button"
                            textTransform="capitalize"
                            minWidth={item.description ? '14rem' : '12rem'}
                            color={item.description ? 'dark' : 'text'}
                            fontWeight={item.description ? 'bold' : 'regular'}
                            py={item.description ? 1 : 0.625}
                            px={2}
                            sx={({ palette: { grey, dark }, borders: { borderRadius } }) => ({
                              borderRadius: borderRadius.md,
                              cursor: 'pointer',
                              transition: 'all 300ms linear',
                              '&:hover': {
                                backgroundColor: grey[200],
                                color: dark.main,
                                '& *': { color: dark.main },
                              },
                            })}
                            onMouseEnter={({ currentTarget }) => {
                              if (item.dropdown) {
                                setNestedDropdown(currentTarget);
                                setNestedDropdownEl(currentTarget);
                                setNestedDropdownName(item.name);
                              }
                            }}
                            onMouseLeave={() => { if (item.dropdown) { setNestedDropdown(null); } }}
                          >
                            {item.description ? (
                              <MDBox display="flex" py={0.25} fontSize="1rem" color="text">
                                <MDBox color="inherit">{item.icon}</MDBox>
                                <MDBox pl={1} lineHeight={0}>
                                  <MDTypography
                                    variant="button"
                                    display="block"
                                    fontWeight="bold"
                                    textTransform="capitalize"
                                  >
                                    {item.name}
                                  </MDTypography>
                                  <MDTypography
                                    variant="button"
                                    fontWeight="regular"
                                    color="text"
                                  >
                                    {item.description}
                                  </MDTypography>
                                </MDBox>
                              </MDBox>
                            ) : (
                              <MDBox display="flex" alignItems="center" color="text">
                                {item.icon}
                                {item.name}
                              </MDBox>
                            )}
                            {item.collapse && (
                              <KeyboardArrowRight
                                sx={{
                                  fontWeight: 'normal',
                                  verticalAlign: 'middle',
                                  mr: -0.5,
                                }}
                              />
                            )}
                          </MDTypography>
                        );

                        return (
                          <Link key={item.name} href={item.route}>
                            <span style={{ cursor: 'pointer' }}>{itemTemplate}</span>
                          </Link>
                        );
                      });
                    }

                    return template;
                  },
                )}
              </MDBox>
            </MDBox>
          </Grow>
        )}
      </Popper>

      {/* // Dropdown menu for the nested dropdowns */}
      <Popper
        anchorEl={nestedDropdown}
        popperRef={null}
        open={Boolean(nestedDropdown)}
        placement="right-start"
        transition
        style={{ zIndex: 999 }}
        onMouseEnter={() => {
          setNestedDropdown(nestedDropdownEl);
        }}
        onMouseLeave={() => {
          setNestedDropdown(null);
          setNestedDropdownName('');
          setDropdown(null);
        }}
      >
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            sx={{
              transformOrigin: 'left top',
              background: ({ palette: { white } }) => white.main,
            }}
          >
            <MDBox ml={2.5} mt={-2.5} borderRadius="lg">
              <MDBox shadow="lg" borderRadius="lg" py={1.5} px={1} mt={2}>
                {/* // Render routes that are nested inside the dropdown menu routes */}
                {routes.map(({ collapse, columns }) => (collapse && !columns
                  ? collapse.map(({ name: parentName, collapse: nestedCollapse }) => {
                    let template;

                    if (parentName === nestedDropdownName) {
                      template = nestedCollapse
                        && nestedCollapse.map((item) => {
                          const nestedItemTemplate = (
                            <MDTypography
                              key={item.name}
                              display="flex"
                              justifyContent="space-between"
                              alignItems="center"
                              variant="button"
                              textTransform="capitalize"
                              minWidth={item.description ? '14rem' : '12rem'}
                              color={item.description ? 'dark' : 'text'}
                              fontWeight={item.description ? 'bold' : 'regular'}
                              py={item.description ? 1 : 0.625}
                              px={2}
                              sx={({
                                palette: { grey, dark },
                                borders: { borderRadius },
                              }) => ({
                                borderRadius: borderRadius.md,
                                cursor: 'pointer',
                                transition: 'all 300ms linear',

                                '&:hover': {
                                  backgroundColor: grey[200],
                                  color: dark.main,

                                  '& *': {
                                    color: dark.main,
                                  },
                                },
                              })}
                            >
                              {item.description ? (
                                <MDBox>
                                  {item.name}
                                  <MDTypography
                                    display="block"
                                    variant="button"
                                    color="text"
                                    fontWeight="regular"
                                    sx={{ transition: 'all 300ms linear' }}
                                  >
                                    {item.description}
                                  </MDTypography>
                                </MDBox>
                              ) : (
                                item.name
                              )}
                              {item.collapse && (
                                <KeyboardArrowRight
                                  fontSize="small"
                                  sx={{
                                    fontWeight: 'normal',
                                    verticalAlign: 'middle',
                                    mr: -0.5,
                                  }}
                                />
                              )}
                            </MDTypography>
                          );

                          return (
                            <Link key={item.name} href={item.route}>
                              {nestedItemTemplate}
                            </Link>
                          );
                        });
                    }
                    return template;
                  })
                  : null))}
              </MDBox>
            </MDBox>
          </Grow>
        )}
      </Popper>
    </Container>
  );
}

// Declaring default props for DefaultNavbar
DefaultNavbar.defaultProps = {
  transparent: false,
  light: false,
  action: false,
};

// Typechecking props for the DefaultNavbar
DefaultNavbar.propTypes = {
  transparent: PropTypes.bool,
  light: PropTypes.bool,
  action: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      type: PropTypes.oneOf(['external', 'internal']).isRequired,
      route: PropTypes.string.isRequired,
      color: PropTypes.oneOf([
        'primary',
        'secondary',
        'info',
        'success',
        'warning',
        'error',
        'dark',
        'light',
        'default',
        'white',
      ]),
      label: PropTypes.string.isRequired,
    }),
  ]),
};

export default DefaultNavbar;
